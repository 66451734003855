.siderbar-profile--top {
    background-size: cover;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    background-image: url('./img/nm.jpg');
    background-position: 50%;
    // background-image: linear-gradient(
    //     to right top,
    //     #29be47,
    //     #1dc15c,
    //     #14c56e,
    //     #12c77f,
    //     #1bca8f
    // ) ;
    opacity: 0.9 !important;
    height: 164px;
    color: #fff;
    z-index: -1;
}

.ant-layout-sider.dashboard-sidebar {
    padding: 0 0 10px;
    z-index: 9999;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('./img/cover2.jpg');
    justify-content: center;
    background-position: 600px 0;
    object-fit: cover;
    background-size: cover;
    background-repeat: repeat-x;
    bottom: 0;
    margin-top: 70px;
    box-shadow: 0.1px 0 0.5px;
    height: 100vh;
    overflow: hidden;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #97BC62FF !important;
    // border-bottom: 2px solid red;
  }

  .ant-tabs-ink-bar{
    background: #97BC62FF !important;
  }

  .view-btn-dark {
    padding: 5px 7px;
    border-radius: 3px;

    background: #97BC62FF;
    color: #fff;
    &:hover {
        background: #fff;
        color: #97BC62FF;
    }
}

.ant-pagination-item-active a{
    color: #97BC62FF !important;
    border-color: #97BC62FF ;
    

}

.ant-pagination-item-active{
    border-color: #97BC62FF  ;
}
.dashboard-container.ant-layout {
    background: #fbfbfb;
    // height: calc(100vh - 70px);

    .ant-layout-header.dashboard-header {
        height: 70px;
        // background: #ffffff;
        color: white;
        // background-image: linear-gradient(
        //     to right top,
        //     #11b56c,
        //     #12ba75,
        //     #15c07e,
        //     #17c586,
        //     #1bca8f
        // );
        // background-image: linear-gradient(to right top, #1bca8f, #2ab073, #2e965a, #2d7e42, #28662d);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-shadow: 0.2px 0px 0.8px;
        position: fixed;
        z-index: 1008;
        width: 100%;
        padding: 0px 30px;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            padding: 0px 20px;
        }

        .dashboard--units {
            cursor: pointer;
        }
        .dashboard--buyvote {
            cursor: pointer;
            color: #13bb77;
            &:hover {
                color: #007f59;
            }
        }
    }
    .siderbar-profile--top {
        background-size: cover;
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        background-image: url(/static/media/sidebarImage.0b29e36f.png);
        background-position: 50%;
        background-color: #1fcb37;
        height: 164px;
        color: #fff;
        z-index: -1;
    }
    .dashboard-header__menu-icon {
        font-size: 20px;
        margin-left: 10px;
    }
    .dashboard-container {
        // margin-left: 10px;
        @media screen and (min-width: 768px) {
            width: 20%;
            margin-left: 0px;
            background: #1bca8f;
        }
        // background-image: linear-gradient(to right top, #11b56c, #12ba75, #15c07e, #17c586, #1bca8f);
        // background-image: linear-gradient(to right top, #1bca8f, #2ab073, #2e965a, #2d7e42, #28662d);
    }
    // .campaign-content:last-child {
    //     //    display: none;
    // }
    .scrollbar:first-child {
        overflow: hidden !important;
    }
    aside {
        position: sticky;
        top: 70px;
        overflow: hidden !important;
        z-index: 0;
    }
    main {
        overflow: hidden;
    }

    .card {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.19);
    }
    .card-action a:nth-child(1) {
        width: 100%;
        background: #1bca8f;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.19);

        border-radius: 10px;
        margin: 0px;
        color: white;
        text-align: center;
    }

    // style={{width: "20%",padding: "10px"}}
    .card-action a:nth-child(2) {
        width: 50%;
        padding: 10px;
        border-radius: 10px;
        margin: 0px;
        // color: rgb(27, 202, 143);

        text-align: center;
    }
    .image-content .image__card-body--img img {
        object-fit: cover;
    }
    .field-item .key h4,
    .field-item h4 {
        color: white;
    }
    .content-bio__content--collapse span {
        color: white;
    }
    .contestant__card--image img {
        object-fit: cover;
    }
    .dashboard-container.ant-layout .content-bio__content--collapse span {
        margin-left: 10px !important;
    }
    .ant-row .image-content {
        height: 160vh !important;
    }
    .content-bio__content {
        // background: linear-gradient(rgba(23, 177, 90, 0.9), rgba(23, 177, 90, 0.9)), url('./img/cover.jpg');

        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
            url('./img/nm.jpg');
        background-position: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.19);
        background-size: cover;
        padding: 30px;
        border-radius: 10px;
    }
    .card-item .card .card-body {
        background: white;
    }
    .card-item .card .card-img img {
        height: 300px !important;
    }
    .dashboard-layout {
        position: relative;
        // height: 100vh;
        background-image: url('./img/we.jpg');
        margin-top: 70px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.7);
        background-blend-mode: overlay;
        margin-top: 70px;
        &__content {
            padding: 20px 12px;
            position: relative;
        }
    }
}

.bellWrapper {
    position: relative;
    display: inline-block;
    text-align: center;
    &__active {
        position: absolute;
        display: inline-block;
        top: 20px;
        right: 11px;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        background-color: #fa5c7c;
    }
}

.dashboard-container.ant-layout .ant-layout-header.dashboard-header {
    z-index: 1010;
}

.dashboard-container.ant-layout aside {
    height: calc(100vh - 70px);
    @media screen and (max-width: 768px) {
        position: absolute !important;
        top: 0px !important;
        overflow: hidden !important;
        z-index: 1009 !important;
        max-height: calc(100vh - 70px);
    }
}
.outsideLayer {
    position: fixed;
    width: 100vw;
    min-width: 100%;
    height: 100%;
    max-height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1003;
}
