body {
    margin: 0;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
}
/* .dashboard-header__menu-icon{
    margin-left: 20% !important;
} */

@media only screen and (max-width: 600px) {
    /*Big smartphones [426px -> 600px]*/
    .fieldrow {
        display: block;
    }
    .applycontestant {
        width: 100% !important;
    }
    .buymore__calc {
        display: none;
    }
    .buymore {
        margin-top: 10vh;
    }
    .go2441762052 {
        display: none;
    }
    .navigation-buttons {
        margin: 7px !important;
    }
}

.ant-table-content {
    overflow-y: scroll;
}
