.about_page {
    padding: 4rem 6rem;
    @media screen and (max-width: 1025px) {
        padding: 3rem 2rem;
    }
    @media screen and (max-width: 568px) {
        padding: 3rem 1rem;
    }
    &__left {
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__right {
        margin: 3rem;
        @media screen and (max-width: 1025px) {
            margin: 1rem;
        }
        &-content {
            font-size: 16px;
            line-height: 25px;
        }
    }
}
