@import '~antd/dist/antd.less';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';
* {
    box-sizing: border-box;
}

.app {
    text-align: center;
}
.text-muted {
    color: #74788d !important;
}
.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}
.ant-alert-error {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    .ant-alert-icon {
        color: #ffe58f;
    }
}

.text-white {
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.c-btn {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 1.05rem;
    will-change: transform;
    color: #fff;
    background-color: #1bca8f;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0px 3px 9px -3px rgba(17, 34, 102, 0.48);
    &:hover {
        transform: translateY(-1px);
        color: #fff;
        // background-color: #fff;
        // border-color: #fff;
    }
}

.box-shadow {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}
.btn-container {
    margin-top: 1.5rem;
    button {
        width: 100%;
        height: 38px;
    }
}

@primary-color: #1bca8f;@font-family: Inter, sans-serif;